import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';
import { Switch } from 'antd';

import { DataContext } from '../../../init/getData';

import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


const TabsPermission = (props) => {

  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { showModal, toggleModal, refreshTable, tittle, idRow } = props;

  const { wareHouseData } = useContext(DataContext);


  const [dashboardTab, setDashboardTab] = useState(false);
  const [salesTab, setSalesTab] = useState(false);
  const [productTab, setProductTab] = useState(false);
  const [clientTab, setClientTab] = useState(false);
  const [orderTab, setOrderTab] = useState(false);
  const [deliveryTab, setDeliveryTab] = useState(false);
  const [accountingTab, setAccountingTab] = useState(false);
  const [reportTab, setReportTab] = useState(false);
  const [settingTab, setSettingTab] = useState(false);
  
  const [loadingEffect, setLoadingEffect] = useState(false);


  useEffect(() => {
    if(idRow){
      setDashboardTab(idRow.dashboardTab)
      setSalesTab(idRow.salesTab)
      setProductTab(idRow.productTab)
      setClientTab(idRow.clientTab)
      setOrderTab(idRow.orderTab)
      setDeliveryTab(idRow.deliveryTab)
      setAccountingTab(idRow.accountingTab)
      setReportTab(idRow.reportTab)
      setSettingTab(idRow.settingTab)
    }
  }, [idRow]);
  
  const handleSubmit = async () => {
    setLoadingEffect(true);

    const id = idRow.id;
    const settings= {
      dashboardTab,
      salesTab,
      productTab,
      clientTab,
      orderTab,
      deliveryTab,
      accountingTab,
      reportTab,
      settingTab,
    }
      
    let res = await api.post(`/role/editPermissions`, JSON.stringify({id, settings}))
    if(res.data.status){
      toast.success(res.data.message);
      refreshTable();
      toggleModal();
    }
    else{
      toast.error(res.data.message);
    }
 
    setLoadingEffect(false);
  }





  return (
      <Modal show={showModal} backdrop="static" size='md' onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2939" }}>
      <Modal.Title style={{ color: "white" }}>{idRow.name} Tabs Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form style={{marginBottom: '10%', marginTop: '5%', alignItems: 'center'}}>

      <Form.Group as={Row} className="mb-9">
        <Form.Label column sm={5}>Dashboard Tab</Form.Label>
          <Switch defaultChecked onChange={() => setDashboardTab(!dashboardTab)} checked = {dashboardTab} />
        </Form.Group>



      <Form.Group as={Row} className="mb-9" >
        <Form.Label column sm={5}>Sales Tab</Form.Label>
          <Switch defaultChecked onChange={() => setSalesTab(!salesTab)} checked = {salesTab} />
        </Form.Group>

      <Form.Group as={Row} className="mb-9">
        <Form.Label column sm={5}>Product Tab</Form.Label>
          <Switch defaultChecked onChange={() => setProductTab(!productTab)} checked = {productTab} />
        </Form.Group>


      <Form.Group as={Row} className="mb-9">
        <Form.Label column sm={5}>Client Tab</Form.Label>
          <Switch defaultChecked onChange={() => setClientTab(!clientTab)} checked = {clientTab} />
      </Form.Group>


      <Form.Group as={Row} className="mb-9">
        <Form.Label column sm={5}>Order Tab </Form.Label>
          <Switch defaultChecked onChange={() => setOrderTab(!orderTab)} checked = {orderTab} />
      </Form.Group>

      <Form.Group as={Row} className="mb-9">
        <Form.Label column sm={5}>Delivery Tab </Form.Label>
          <Switch defaultChecked onChange={() => setDeliveryTab(!deliveryTab)} checked = {deliveryTab} />
      </Form.Group>


      <Form.Group as={Row} className="mb-9">
        <Form.Label column sm={5}>Accounting Tab</Form.Label>
          <Switch defaultChecked onChange={() => setAccountingTab(!accountingTab)} checked = {accountingTab} />
      </Form.Group>


      <Form.Group as={Row} className="mb-9">
        <Form.Label column sm={5}>Report Tab</Form.Label>
          <Switch defaultChecked onChange={() => setReportTab(!reportTab)} checked = {reportTab} />
      </Form.Group>


      <Form.Group as={Row} className="mb-9">
        <Form.Label column sm={5}>Setting Tab</Form.Label>
          <Switch defaultChecked onChange={() => setSettingTab(!settingTab)} checked = {settingTab} />
      </Form.Group>


      <Form.Group as={Row} className="mb-9">

      </Form.Group>



 


      </Form>
     
      
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>

        <button type="button" className="btn btn-primary" onClick={handleSubmit}>
        Save Data
        </button>

        {loadingEffect && ( 
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        )}

      </div>

 
      </Modal.Body>
      </Modal>
      )
}


export default TabsPermission;