import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, NavLink } from 'react-router-dom';

import { NavDropdown } from 'react-bootstrap';

import CostChangesHeader from './costChanges/costChangesHeader';
import StockActivity from './stockActivity/stockActivity';

import ProductSales from './productSales/productSales';
import ProductSalesByInvoice from './productSalesByInvoice/productSalesByInoivce';
import WarrentyHeader from './warranty/warrantyHeader';
import IncomeStatement from './incomeStatement/incomeStatement';
import AllTransactions from './allTransactions/allTransactions';

import StockValue from './stockValue/stockValue';

import { DataContext } from '../init/getData';

import { FcPackage, FcSurvey, FcSalesPerformance, FcApproval, FcDeployment, FcComboChart } from "react-icons/fc";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { MdOutlineDiscount } from "react-icons/md";

function ReportTab(props) {

  const navigate = useNavigate();

  const { api, userData, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const [activeTab, setActiveTab] = useState('stockActivity');

  useEffect(() => {
    console.log(userRole.warrantyPage)
    navigate('/reportTab/stockActivity');

  }, []);


  return (
    <div className="card" style={{ paddingTop: '0px', userSelect: 'none' }}>

      <div>

        {!userRole.costChangesPage &&
          <NavLink to="costChanges" onClick={() => setActiveTab('barcode')}>
            <div className="tab-one ">
              <FcPackage size={33} />
              <span className="tab-text">Cost Changes</span>
            </div>
          </NavLink>
        }

        {userRole.stockActivityPage &&
          <NavLink to="stockActivity" onClick={() => setActiveTab('stockActivity')}>
            <div className={`${activeTab === 'stockActivity' ? 'tab-two active-link' : 'tab-two'}`}>
              <FaArrowRightArrowLeft size={25} />
              <span className="tab-text">Stock Activity</span>
            </div>
          </NavLink>
        }


        {userRole.stockValuePage &&
          <NavLink to="stockValue" onClick={() => setActiveTab('stockValue')}>
            <div className={`${activeTab === 'stockValue' ? 'tab-three active-link' : 'tab-three'}`}>
              <FcSalesPerformance size={30} />
              <span className="tab-text">Stock Value</span>
            </div>
          </NavLink>
        }


        {settingData.serializedProduct &&
          <NavLink to="warranty" onClick={() => setActiveTab('warranty')}>
            <div className={`${activeTab === 'warranty' ? 'tab-four active-link' : 'tab-four'}`} >
              <FcApproval size={35} />
              <span className="tab-text">Warranty</span>
            </div>
          </NavLink>
        }


        {userRole.productSalesPage &&
          <div onClick={() => setActiveTab('productSales')} className={`${activeTab === 'productSales' ? 'tab-four active-link' : 'tab-four'}`}>
            <MdOutlineDiscount size={30} color={'#ffccbc'} />
            <NavDropdown title={<span className="navDropdown">Product Sales</span>} >
              <NavDropdown.Item onClick={() => navigate("productSalesByItems")} style={{ color: 'white !important' }}>By Items</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("ProductSalesByInvoice")} style={{ color: 'white !important' }}>By Invoice</NavDropdown.Item>
            </NavDropdown>
          </div>
        }


        <NavLink to="AllTransactions" onClick={() => setActiveTab('AllTransactions')}>
          <div className={`${activeTab === 'AllTransactions' ? 'tab-six active-link' : 'tab-six'}`} style={{ width: 'auto' }}>
            <FcComboChart size={35} />
            <span className="tab-text">All Transactions</span>
          </div>
        </NavLink>


        <NavLink to="incomeStatement" onClick={() => setActiveTab('incomeStatement')}>
          <div className={`${activeTab === 'incomeStatement' ? 'tab-seven active-link' : 'tab-seven'}`} style={{ width: 'auto' }}>
            <FcSurvey size={28} />
            <span className="tab-text">Income Statement</span>
          </div>
        </NavLink>

      </div>


      <Routes>

        <Route path='costChanges' element={<CostChangesHeader />} />
        <Route path='stockActivity' element={<StockActivity />} />
        <Route path='stockValue' element={<StockValue />} />
        <Route path='warranty' element={<WarrentyHeader />} />
        <Route path='productSalesByItems' element={<ProductSales type={'item'} />} />
        <Route path='ProductSalesByInvoice' element={<ProductSalesByInvoice/>} />
        <Route path='AllTransactions' element={<AllTransactions />} />
        <Route path='incomeStatement' element={<IncomeStatement />} />

      </Routes>

    </div>
  );
}

export default ReportTab;
