import React, { useState, useEffect, useContext } from 'react';
import ReactDOMServer from 'react-dom/server';

import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';
import { defaultDateGetter, dateHourFormatter } from '../../dynamic/dateFormatter';

import { FaPlus } from "react-icons/fa";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs, Card, ConfigProvider, Divider } from 'antd';


import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';




function IncomeStatement(props) {

  let { } = props;


  const { userData, settingData, serverApi, api } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);
  const closingHour = settingData.closingHour;

  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);

  const [filter, setFilter] = useState({ search: '', isActive: 'true', fromDate: today, toDate: today });

  const [totalSales, setTotalSales] = useState([]);
  const [totalProfit, setTotalProfit] = useState([]);
  const [totalDiscounts, setTotalDiscounts] = useState([]);

  const [expenses, setExpenses] = useState([]);
  const [expensesBalance, setExpensesBalance] = useState([]);

  const totalCost = totalSales - totalProfit;

  // idRow Change
  useEffect(() => {
    fetchData();
  }, [filter.fromDate, filter.toDate]);


  const fetchData = async () => {

    setIsLoading(true);
 

    let data = {
      startRow: null,
      endRow: null,
      filter: filter,
    };
   
    try {
      const response = await api.get(`/productSales/getall`, { params: data })
      if (response) {
        setTotalSales(response?.data?.summary?.totalSales);
        setTotalProfit(response?.data?.summary?.totalProfit);
        setTotalDiscounts(response?.data?.summary?.totalDiscounts);
      }

      let expensesData = {
        filter: {
          isActive: 'true',
          fromDate: filter.fromDate,
          toDate: filter.toDate,
          type: 'Expenses'
        }
      };
      const getExpenses = await api.get(`account/getall`, { params: expensesData })
      if (response) {
        const expenseAccounts = getExpenses?.data?.data;
        const totalExpenseBalance = getExpenses?.data?.summary?.totalAllInvoice;
        setExpenses(expenseAccounts);
        setExpensesBalance(totalExpenseBalance);
      }
    }
    catch (error) {
      toast.error('Contact Supportss');
    }

    setIsLoading(false);
  }

  const handlePrint = () => {
    // Create a new window
    const newWindow = window.open('', '_blank');

    // Define your card content as a JSX element or string
    const cardContent = ReactDOMServer.renderToStaticMarkup(card);

    // Write the content to the new window's document
    newWindow.document.open();
    newWindow.document.write(cardContent);
    newWindow.document.close();

    // Print the new window
    newWindow.print();

    // Close the new window after printing
    newWindow.close();
  };

  const card =
    (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#4a90e2', // Change this to your desired color
          },
          components: {
            Card: {
              colorBgContainer: '#e4e9f7', // Background color of the card
              headerBg: '#39aaa5', // Header background color
              headerFontSize: '20px', // Font size for the header
              colorTextHeading: 'white', // Font color for the headers

            },
          },
        }}>


        <Card
          title={<a style={{ marginLeft: '37%' }}>Income Statement</a>}
          bordered={true}
          style={{ width: 800, alignSelf: 'center' }}>


          {/* Sales  */}
          <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Sales</h5>


          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Sales Revenue:</h5>
              <h5>{numberFormatter(totalSales, 2)}{settingData.currency}</h5>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Cost of goods sold:</h5>
              <h5>{numberFormatter(totalCost, 2)}{settingData.currency}</h5>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Sales Discounts:</h5>
              <h5>{numberFormatter(totalDiscounts, 2)}{settingData.currency}</h5>
            </div>

            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Gross Profit:</h5>
              <h5 style={{ color: 'green' }}>{numberFormatter(totalProfit, 2)}{settingData.currency}</h5>
            </div>


            {/* Expenses  */}
            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: '30px' }}>Operating Expenses</h5>

            {expenses?.map((expense, index) => (
              <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>{expense.name}:</h5>
                <h5>{numberFormatter(expense.totalInvoice, 2)}{settingData.currency}</h5>
              </div>
            ))}

            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>Total:</h5>
              <h5 style={{ color: 'red' }}>{numberFormatter(-expensesBalance, 2)}{settingData.currency}</h5>
            </div>



            {/* Final  */}
            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: '30px' }}>Final</h5>


            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Gross Profit:</h5>
                <h5>{numberFormatter(totalProfit, 2)}{settingData.currency}</h5>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Expenses:</h5>
                <h5>{numberFormatter(-expensesBalance, 2)}{settingData.currency}</h5>
              </div>

              <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5 style={{ color: 'green' }}>Net Income:</h5>
                <h5 style={{ color: 'green' }}>{numberFormatter(totalProfit - expensesBalance, 2)}{settingData.currency}</h5>
              </div>

            </div>


          </div>
        </Card>
      </ConfigProvider>)
    ;


  return (

    <div className='card'>

      <DatePicker setFilter={setFilter} filter={filter} />



      {isLoading &&
        <Loader />
      }




      {!isLoading &&
        <>
          <h5></h5>
  



          {card}


        </>
      }

      
    </div>

  )
}

export default IncomeStatement;
