import React, { useEffect, useState, useContext } from 'react';
import { Table, Pagination, ConfigProvider } from 'antd';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import { DataContext } from '../../init/getData';
import toast from 'react-hot-toast';

import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { TiExport } from "react-icons/ti";
import { BiExport } from "react-icons/bi";
import  exportToExcel  from '../export/excel';

const SimpleTable = (props) => {

  const { filter, table, refreshTable, columnDefs, data, setData, tableFooter,
    height, getAllData, rowColor, setCheckedArray, selection, api, getExcelData, title } = props;

  const { userData, settingData, refCompany } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({ pagination: { current: 1, pageSize: 60, }, });

  const fetchData = async () => {
    setLoading(true);
    
    const sortField = tableParams.field;
    const sortDirection = tableParams.order;

    let data = {
      filter: filter,
      startRow: getAllData ? null : (tableParams.pagination.current - 1) * tableParams.pagination.pageSize,
      endRow: getAllData ? null : (tableParams.pagination.current) * tableParams.pagination.pageSize,
      sortField: sortField,
      sortDirection: sortDirection,
    };


    if (tableParams.column) {
      data.sortField = tableParams.field;
      data.sortDirection = tableParams.order == "ascend" ? 'asc' : 'desc';
    }

    await api.get(`/${table}/getall`, { params: data }).then(response => {
      if (!response.data.status) {
        toast.error('Failed to get Data')
      } else {
        setData(response.data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data.totalRecords,
            showSizeChanger: false,
          },
        });
      }
    })
  };



  const exportDataToExcel = async () => {
    setLoading(true);
    let data = {
      filter: filter,
      startRow: null,
      endRow: null,
    };


    if (tableParams.column) {
      data.sortField = tableParams.field;
      data.sortDirection = tableParams.order == "ascend" ? 'asc' : 'desc';
    }

    await api.get(`/${table}/getall`, { params: data }).then(response => {
      if (!response.data.status) {
        toast.error('Failed to get Data')
      } else {
        setLoading(false);
        const excelData = getExcelData(response.data.data, response.data.summary);
        exportToExcel(excelData, title);
      }
    })


  };


  useEffect(() => {
    fetchData();
  }, [tableParams.pagination.current, tableParams.field, tableParams.order, filter, refreshTable]);




  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };



  const customPagination = {
    ...tableParams.pagination,
    itemRender: (current, type, originalElement) => {
      if (type === 'prev') {
        return <IoIosArrowBack size={20} style={{ marginRight: '12px' }} />;
      }
      if (type === 'next') {
        return <IoIosArrowForward size={20} style={{ marginLeft: '12px' }} />;
      }
      return originalElement;
    },
    showTotal: (total, range) => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div  >
          {tableFooter}
        </div>
        <div>{`Total:  ${total} items`}</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', left: '8px', transform: 'translateX(-50%)', bottom: '-10px' }}>
          <DropdownButton
            drop={'up'}
            title={<BiExport size={30} color={'#2b7b76'} />}
            size={'sm'}
            variant="white"
            className="custom-dropdown-button">
            <Dropdown.Item eventKey="1" onClick={() => exportDataToExcel()}>Excel .xlsx</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="2">Report .pdf</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    ),
  };



  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (setCheckedArray) {
        setCheckedArray(selectedRowKeys)
      }
    },
  };


  return (

    <div style={{ userSelect: 'none' }}>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#39aaa5',
              headerColor: 'white',
              rowHoverBg: '#bcdffb',
              headerSortActiveBg: '#00709d',
              headerSortHoverBg: '#00709d',
            },
          },
        }}
      >


        <Table
          className={height ? 'ant_table_mini' : 'ant_table'}
          columns={columnDefs}
          rowSelection={selection ? {
            type: 'checkbox',
            ...rowSelection,
          } : null}
          rowKey={(record) => record.id}
          dataSource={data ? data.data : null}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ y: height ? '53vh' : '66vh' }}
          size="small"
          pagination={customPagination}
          rowClassName={(record, index) => {
            if (rowColor) {
              return rowColor(record, index);
            } else {
              return index % 2 === 0 ? 'even-row' : 'odd-row';
            }
          }}
        />

      </ConfigProvider>

    </div>
  );
};

export default SimpleTable;
