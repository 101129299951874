import React, {  useState, useEffect, useContext} from 'react';
import { Route, Routes, useNavigate, NavLink} from 'react-router-dom';


import Account from './account/account';
import Delivery from './delivery/delivery';
import WareHouse from './wareHouse/wareHouse';
import Chart from './chart/chart';

import { FcBusinessman, FcManager, FcPortraitMode, FcAutomotive , FcShipped, FcHome, FcDonate } from "react-icons/fc";

import { DataContext } from '../init/getData';

function AccountTab(props) {

  const navigate = useNavigate();

  const {settingData, userData} = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;


  const [activeTab, setActiveTab] = useState('customer');

  
  useEffect(() => {

    navigate('/accountTab/customer');
  }, []);


  return (
    <div className="card" style={{paddingTop : '0px', userSelect: 'none'}}>
      
      <div>

      {userRole.customerPage &&
        <NavLink to="customer"  onClick={() => setActiveTab('customer')}>
          <div className={`${activeTab === 'customer' ? 'tab-one active-link' : 'tab-one'}`} style={{width: '12%'}}>
            <FcPortraitMode size={35}/>
            <span className="tab-text">Customers</span>
          </div>
        </NavLink>
      }


         {userRole.supplierPage &&
        <NavLink to="supplier"  onClick={() => setActiveTab('supplier')}>
          <div className={`${activeTab === 'supplier' ? 'tab-two active-link' : 'tab-two'}`} style={{width: '12%'}}>
          <FcBusinessman size={35}/>
            <span className="tab-text">Suppliers</span>
          </div>
        </NavLink>
        }


        <NavLink to="chart"  onClick={() => setActiveTab('chart')}>
          <div className={`${activeTab === 'chart' ? 'tab-four active-link' : 'tab-four'}`} style={{width: '12%'}}>
          <FcAutomotive  size={30}/>
            <span className="tab-text">Chart Of Accounts</span>
          </div>
        </NavLink>

        <NavLink to="expenses"  onClick={() => setActiveTab('expenses')}>
          <div className={`${activeTab === 'expenses' ? 'tab-five active-link' : 'tab-five'}`} style={{width: '12%'}}>
          <FcDonate size={30}/>
            <span className="tab-text">Expenses</span>
          </div>
        </NavLink>

 
 
        <NavLink to="wareHouse"  onClick={() => setActiveTab('wareHouse')}>
          <div className={`${activeTab === 'wareHouse' ? 'tab-six active-link' : 'tab-six'}`} style={{width: '12%'}}>
          <FcHome size={30}/>
            <span className="tab-text">WareHouse</span>
          </div>
        </NavLink>
     

          {userRole.deliveryPage &&
        <NavLink to="delivery"  onClick={() => setActiveTab('delivery')}>
          <div className={`${activeTab === 'delivery' ? 'tab-seven active-link' : 'tab-seven'}`} style={{width: '12%'}}>
          <FcShipped size={35}/>
            <span className="tab-text">Delivery Co</span>
          </div>
        </NavLink>
          }


      
      </div>

 
      <Routes>
        <Route path='customer' element= {<Account type={'Customer'}/>}/>
        <Route path='supplier' element= {<Account type={'Supplier'}/>}/>
        <Route path='employee' element= {<Account type={'Employee'}/>}/>
        <Route path='expenses' element= {<Account type={'Expenses'}/>}/>
        
        <Route path='chart'   element= {<Chart/>}/>

        <Route path='delivery' element= {<Delivery/>}/>
        <Route path='wareHouse' element= {<WareHouse/>}/>
        
      </Routes>

    </div>
  );
}

export default AccountTab;
