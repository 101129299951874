
//const link = 'http://localhost:5001/api';

//const link = 'https://admin.bakeryalhadi.com:5011/api';

//const link = 'https://accounting.eastmedsolutions.com:5007/api';
//const link = 'https://fashionsista.riserteam.com:5010/api';
const link = 'https://thebarberlounge.riserteam.com:5012/api';

//const link = 'http://localhost:5000/api';


export  { link};