
import { ClockCircleOutlined, HddOutlined, TruckOutlined, LikeOutlined, RetweetOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Input, Tag, Button } from 'antd';

const typeFormatter = (type, record) => {
  if (record.isCredit) {
    return <Tag color="#f0b103"
      style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{type}</Tag>
  }
  else {
    if (record.isOut) {
      return <Tag color="#ff4364"
        style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{type}</Tag>
    } else {
      return <Tag color="#2eb85c"
        style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{type}</Tag>
    }
  }

}


const stockActivitytypeFormatter = (transaction, record) => {

  if (!transaction.isOut) {
    return <Tag color="#ff4364"
      style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{transaction.type}</Tag>
  } else {
    return <Tag color="#2eb85c"
      style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{transaction.type}</Tag>
  }

}

const productSalesFormatter = (transaction, record) => {

  if (transaction.isOut) {
    return <Tag color="#ff4364"
      style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{transaction.type}</Tag>
  } else {
    return <Tag color="#2eb85c"
      style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{transaction.type}</Tag>
  }

}


const paymentTypeFormatter = (isCredit, record) => {
  if (isCredit) {
    return <Tag color="#f0b103"
      style={{ cursor: 'pointer', fontSize: '15px', width: '80%', textAlign: "center" }}>Credit</Tag>
  } else if (record.isOut) {
    return <Tag color="#ff4364"
      style={{ cursor: 'pointer', fontSize: '15px', width: '80%', textAlign: "center" }}>Cash</Tag>
  } else {
    return <Tag color="#2eb85c"
      style={{ cursor: 'pointer', fontSize: '15px', width: '80%', textAlign: "center" }}>Cash</Tag>
  }

}

export {
  typeFormatter,
  paymentTypeFormatter,
  stockActivitytypeFormatter,
  productSalesFormatter,
};