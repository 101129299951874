import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';
import CartModal from '../../components/modals/cartModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import IsOrderFilter from '../../components/filters/isOrderFilter';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';


import { FcKindle } from "react-icons/fc";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter, decimalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter } from '../../components/formatters/defFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';

import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcRefresh, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';
import e from 'cors';




function StockValue(props) {

  let { type } = props;


  const { api, userData, settingData, wareHouseData, refWareHouseData, deliveryData } = useContext(DataContext);
  const createdBy = parseInt(userData.id);
  const roundTo = settingData.roundTo;
  const userRole = userData.role;
  const defaultWareHouseId = userData.role.defaultWareHouseId;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);


  const table = 'stockValue';
  const title = 'Stock Value';


  const [idRow, setIdRow] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true', wareHouseId: defaultWareHouseId, isLowInStock: false });


  const [showCartModal, setShowCartModal] = useState(false);

  const [transaction, setTransaction] = useState(false);


  //Get Data 
  useEffect(() => {
    const fetchData = async () => {
      try {
        await refWareHouseData();
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);



  // WareHouse Options
  const wareHouseOptions = wareHouseData?.map(item => ({
    value: item.id,
    label: item.name,
  }))

  const selectedWareHouse = wareHouseOptions.filter(option => option.value === filter.wareHouseId);

  const reStock = async () => {
    try {
      setIsLoading(true);
      const data = {
        wareHouseId: filter.wareHouseId,
      }
      let res = await api.post(`stockValue/reStockWareHouse`, data);

      if (res.data.status) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed Contact Support");
      setIsLoading(false);
    }
  }

  //Excel Data
  const getExcelData = (data, getSummary) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        id: item.id,
        barcode: item.barcode,
        category: item.category?.name,
        name: item.name,
        totalStock: item.totalStock,
        cost: item.cost,
        price: item.price,
        type: item.type,
      };

      transformedData.push(newItem);
    });
 
    const summary = {
      Quantity :getSummary.totalProducts,
      LowInStock :getSummary.totalLowInStock,
      StockValue :getSummary.stockCost,
      ExpectedProfit :getSummary.totalExpectedProfit,
    }

    return {transformedData, summary};
  };


  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '6%' },
    { title: 'Barcode', dataIndex: 'barcode' },
    { title: 'Category', dataIndex: 'category', render: (category) => (category?.name) },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Stock', dataIndex: 'totalStock', render: (totalStock, record) => (totalStock + ' ' + record.unit) },
    { title: 'Cost', dataIndex: 'cost', width: '8%' },
    { title: 'Price', dataIndex: 'price', width: '8%' },
    { title: 'Type', dataIndex: 'type' },
  ];

  useEffect(() => {
    setColumnDefs(newColumnDefs);
  }, [filter.isActive]);


  const formView = '';


  const tableFooter = (

    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', left: '45%', transform: 'translateX(-50%)' }}>
      <div>

        <span className='table-footer footer-yellow'>Quantity: {numberFormatter(data?.summary?.totalProducts, 2)}</span>
        <span className='table-footer footer-red'>Low In Stock: {numberFormatter(data?.summary?.totalLowInStock, 2)} </span>
        <span className='table-footer footer-one'>Stock Value:   {numberFormatter(data?.summary?.stockCost, 2)} {settingData.currency}</span>
        <span className='table-footer footer-green'>Expected Profit: {numberFormatter(data?.summary?.totalExpectedProfit, 2)} {settingData.currency}</span>


      </div>
    </div>

  );

  const modalFooter = (
    <div style={{ textAllign: 'center' }}>

      {/* Total */}
      <Row className="mb-3" style={{ marginTop: '2%', justifyContent: 'center' }}>
        <Form.Group as={Col} style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Label></Form.Label>

        </Form.Group>
      </Row>
    </div>
  )

  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }


      {!isLoading &&
        <>
          <div>

            <SearchFilter setFilter={setFilter} />

            <DataFilter selectedOption={selectedWareHouse} setFilter={setFilter} dataOptions={wareHouseOptions} filterBy={'wareHouseId'} placeHolder={'All WareHouse'} />

            <select
              className="form-control dropDown"
              onChange={(e) => setFilter(prevFilter => ({
                ...prevFilter,
                isLowInStock: e.target.value === 'true'
              }))}>
              <option value='false' >All Stock</option>
              <option value='true'>Low In Stock</option>
            </select>

            <CustomButton tittle={'reStock'} method={reStock}/>


          </div>



          <SimpleTable
            filter={filter}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />



        </>
      }

    </div>
  )
}

export default StockValue;
