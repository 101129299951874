import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';
import CartModal from '../../components/modals/cartModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import IsOrderFilter from '../../components/filters/isOrderFilter';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';


import { FcKindle } from "react-icons/fc";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter, decimalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter } from '../../components/formatters/defFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';

import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcRefresh, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';




function Transaction(props) {

  let { type } = props;


  const { api, userData, settingData, accountData, refAccountData, deliveryData, refDeliveryData } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);
  const roundTo = settingData.roundTo;

  const table = 'transaction';
  const title = type + ' Transactions';


  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true', accountType: type, accountId: null, fromDate: today, toDate: today, isOrder: false });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);

  const [transaction, setTransaction] = useState(false);

  //change Type
  useEffect(() => {
    setFilter(prevFilter => ({ ...prevFilter, accountType: type, accountId: null, deliveryId: null }));
  }, [type]);


  //Get Accounts 
  useEffect(() => {
    const fetchData = async () => {
      try {
        await refAccountData();
        await refDeliveryData();
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);


  //Get accounts by Type
  const accountDataType = accountData?.filter(account => account.type === type);

  // Account Options
  const accountOptions = accountDataType?.map(item => ({
    value: item.id,
    label: `${item.name} [${item.phone}]`,
  }))
  accountOptions.push({ value: null, label: 'All ' + type })

  // Delivery Options
  const deliveryOptions = deliveryData?.map(item => ({
    value: item.id,
    label: `${item.name} [${item.phone}]`,
  }))
  deliveryOptions.push({ value: null, label: 'All ' + type })

  const selectedDelivery = deliveryOptions.filter(option => option.value === filter.deliveryId);
  const selectedAccount = accountOptions.filter(option => option.value === filter.accountId);

  // idRow Change
  useEffect(() => {
    setTransaction(
      {
        isOut: idRow?.isOut || false,
        isCredit: idRow?.isCredit || false,
        total: idRow?.total || '',
        details: idRow?.details || '',
        accountId: idRow?.accountId || filter.accountId,
        deliveryId: idRow?.deliveryId || filter.deliveryId,
        createdBy: createdBy,
        type: idRow?.isOut ? 'Payment' : 'Receive' || 'Receive',
      }
    )
  }, [idRow]);


  // isOut Change
  useEffect(() => {
    setTransaction((prev) => ({
      ...prev,
      type: transaction.isOut ? 'Payment' : 'Receive',
    }));
  }, [transaction.isOut]);



  const resetForm = () => {
    setIdRow([]);
    setTransaction({
      createdBy: createdBy,
      type: 'Payment',
      details: "",
      isOut: false,
      accountId: filter.accountId,
    })
  }


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setTransaction((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const addModal = () => {
    if (filter.accountId || filter.deliveryId) {
      setIdRow([]);
      resetForm();
      setShowAddModal(!showAddModal);
    } else {
      toast.error('Please Select Account')
    }
  }

  const cartModal = (e) => {
    if (!showCartModal) {
      setIdRow(e);
    }
    setShowCartModal(!showCartModal);
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }




  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  //Excel Data
  const getExcelData = (data, getSummary) => {

    const transformedData = [];
    data?.forEach(item => {

      const newItem = {
        id: item.id,
        createdAt: item.createdAt,
        account: item.account?.name,
        delivery: item.delivery?.name,
        total: item.isOut ? -item.total : item.total,
        discount: item.discount,
        type: item.type,
        isCredit: item.isCredit,
        details: item.details,
      };

      transformedData.push(newItem);
    });

    let summary;
    if (type == 'Customer') {
      summary = {
        TotalCash: getSummary?.totalCash,
        TotalCredit: getSummary?.totalCredit,
        Balance: getSummary?.totalCash - getSummary?.totalCredit,
      }
    } else if (type == 'Supplier') {
      summary = {
        TotalCash: getSummary?.totalCash,
        TotalCredit: -getSummary?.totalCredit,
        Balance: getSummary?.totalCash - getSummary?.totalCredit,
      }
    } else if (type == 'Employee' || type == 'Expenses') {
      summary = {
        Balance: getSummary?.totalCash - getSummary?.totalCredit,
      }
    }


    return { transformedData, summary };
  };


  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '6%', sorter: (a, b) => a.age - b.age },
    { title: 'CreatedAt', dataIndex: 'createdAt', render: dateTableFormatter },
    { title: 'Account', dataIndex: 'account', render: (account) => (account?.name) },
    { title: 'Delivery', dataIndex: 'delivery', render: (delivery) => (delivery?.name) },
    { title: 'Total', dataIndex: 'total', width: '8%' },
    { title: 'Discount %', dataIndex: 'discount', width: '8%' },
    { title: 'Type', dataIndex: 'type', render: typeFormatter },
    { title: 'Details', dataIndex: 'details' },
    { title: 'cum Balance', dataIndex: 'cumulativeBalance' },
  ];

  useEffect(() => {

    if (filter.isActive == 'true') {
      newColumnDefs.push(
        {
          title: 'View',
          width: '5%',
          render: (_, record) => (
            <FcKindle size={18} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => cartModal(record)} />
          ),
        },
        {
          title: 'Edt',
          width: '5%',
          render: (_, record) => {
            if (record.type == 'Payment' || record.type == 'Receive') {
              return (<FiEdit size={16} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => editModal(record)} />);
            }
            return null;
          },
        },
        {
          title: 'Del',
          width: '5%',
          render: (_, record) => {
            if (record.type == 'Payment' || record.type == 'Receive') {
              return (<BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => deleteModal(record)} />);
            }
            return null;
          },
        }
      );
    } else {
      newColumnDefs.push({
        title: 'Rec',
        width: '3%',
        render: (_, record) => (
          <FcUndo size={20} style={{ cursor: 'pointer' }} onClick={() => recoverModal(record)} />
        ),
      });
    }
    setColumnDefs(newColumnDefs);

  }, [filter.isActive]);

  const formView = (
    <div style={{ userSelect: 'none' }}>

      {/*   Payment  */}
      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
          <Form.Label>Payment</Form.Label>
          <select className="form-control" name='isCredit'
            value={transaction.isCredit}
            onChange={handleInputChange}
            required
          >
            <option value='false'>Cash</option>
            <option value='true'>Credit</option>
          </select>
        </Form.Group>
      </Row>



      {/* in or out  */}
      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
          <Form.Label>Type</Form.Label>
          <select className="form-control" id='isOut' name='isOut'
            value={transaction.isOut}
            onChange={handleInputChange}
            required
          >
            <option value='false'>In</option>
            <option value='true'>Out</option>
          </select>
        </Form.Group>
      </Row>


      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
          <Form.Label>Total</Form.Label>
          <input type="text" name="total" id="total" className="form-control"
            value={transaction.total}
            onChange={handleInputChange}></input>
        </Form.Group>

      </Row>




      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
          <Form.Label>Details</Form.Label>
          <textarea className="form-control" name="details" id="details"
            value={transaction.details}
            onChange={handleInputChange}></textarea>
        </Form.Group>

      </Row>


    </div>
  )

  const tableFooter = (

    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      <div>
        {type == 'Customer' &&
          <>
            <span className='table-footer footer-one'>TotalSales:   {numberFormatter(data?.summary?.totalSales, 2)} {settingData.currency}</span>
            <span className='table-footer footer-green'>Payments:   {numberFormatter(data?.summary?.totalCash, 2)} {settingData.currency}</span>
            <span className='table-footer footer-yellow'>Credit: {numberFormatter(data?.summary?.totalCredit, 2)} {settingData.currency}</span>
            <span className='table-footer footer-one'>Balance: {numberFormatter(data?.summary?.totalCash - data?.summary?.totalCredit, 2)} {settingData.currency}</span>
          </>
        }
        {type == 'Delivery' &&
          <>
            <span className='table-footer footer-green'>Payments:   {numberFormatter(data?.summary?.totalCash, 2)} {settingData.currency}</span>
            <span className='table-footer footer-yellow'>Credit: {numberFormatter(data?.summary?.totalCredit, 2)} {settingData.currency}</span>
            <span className='table-footer footer-one'>Balance: {numberFormatter(data?.summary?.totalSalesCash, 2)} {settingData.currency}</span>
          </>
        }
        {type == 'Supplier' &&
          <>
            <span className='table-footer footer-one'>TotalPurchase:   {numberFormatter(-data?.summary?.totalSales, 2)} {settingData.currency}</span>
            <span className='table-footer footer-green'>Payments:   {numberFormatter(-data?.summary?.totalCash, 2)} {settingData.currency} </span>
            <span className='table-footer footer-yellow'>Credit: {numberFormatter(-data?.summary?.totalCredit, 2)} {settingData.currency}</span>
            <span className='table-footer footer-one'>Balance: {numberFormatter(data?.summary?.totalCash - data?.summary?.totalCredit, 2)} {settingData.currency}</span>
          </>
        }

        {type == 'Expenses' &&
          <>
             <span className='table-footer footer-one'>TotalExpenses:   {numberFormatter(-data?.summary?.totalSales, 2)} {settingData.currency}</span>
            <span className='table-footer footer-green'>Payments:   {numberFormatter(-data?.summary?.totalCash, 2)} {settingData.currency} </span>
            <span className='table-footer footer-yellow'>Credit: {numberFormatter(-data?.summary?.totalCredit, 2)} {settingData.currency}</span>
            <span className='table-footer footer-one'>Balance: {numberFormatter(data?.summary?.totalCash - data?.summary?.totalCredit, 2)} {settingData.currency}</span>
          </>
        }
        {type == 'Employee'  &&
          <>
            <span className='table-footer footer-one'>Balance: {numberFormatter(data?.summary?.totalCash - data?.summary?.totalCredit, 2)} {settingData.currency}</span>
          </>
        }
      </div>
    </div>

  );

  const modalFooter = (
    <div style={{ textAllign: 'center' }}>

      {/* Total */}
      <Row className="mb-3" style={{ marginTop: '2%', justifyContent: 'center' }}>
        <Form.Group as={Col} style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Label></Form.Label>

        </Form.Group>
      </Row>
    </div>
  )

  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }


      {!isLoading &&
        <>
          <div>

            <SearchFilter setFilter={setFilter} />



            <DatePicker setFilter={setFilter} filter={filter} />

            {type != 'Delivery' &&
              <DataFilter selectedOption={selectedAccount} setFilter={setFilter} dataOptions={accountOptions} filterBy={'accountId'} placeHolder={'All ' + type} />
            }

            {type == 'Delivery' &&
              <DataFilter selectedOption={selectedDelivery} setFilter={setFilter} dataOptions={deliveryOptions} filterBy={'deliveryId'} placeHolder={'All ' + type} />
            }

            {(type == 'Customer' || type == 'Delivery') &&
              <IsOrderFilter setFilter={setFilter} />
            }

            <AddButton addModal={addModal} />



          </div>



          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ transactionId: idRow.id, transaction }}
          />

          <CartModal
            showModal={showCartModal}
            toggleModal={cartModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ transaction }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            footer={modalFooter}
            api={api}
            data={{ transactionId: idRow.id, transaction }}
          />


        </>
      }

    </div>
  )
}

export default Transaction;
