import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { DataContext } from '../../../init/getData';

import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


const PagesPermission = (props) => {

  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { showModal, toggleModal, refreshTable, tittle, idRow } = props;

  const { wareHouseData } = useContext(DataContext);



  //Product Tab
  const [productPage, setProductPage] = useState(false);
  const [categoryPage, setCategoryPage] = useState(false);
  const [stockControlPage, setStockControlPage] = useState(false);

   //client Tab
   const [customerPage, setCustomerPage] = useState(false);
   const [supplierPage, setSupplierPage] = useState(false);
   const [wareHousePage, setWareHousePage] = useState(false);
   const [deliveryPage, setDeliveryPage] = useState(false);

    //Accounting Tab
    const [customerAccountPage, setCustomerAccountPage] = useState(false);
    const [supplierAccountPage, setSupplierAccountPage] = useState(false);
    const [purchaseInvoicePage, setPurchaseInvoicePage] = useState(false);
    const [returnInvoicePage, setReturnInvoicePage] = useState(false);
    const [expensesPage, setExpensesPage] = useState(false);
    const [cashPage, setCashPage] = useState(false);

    //Report Tab
    const [costChangesPage, setCostChangesPage] = useState(false);
    const [stockActivityPage, setStockActivityPage] = useState(false);
    const [stockValuePage, setStockValuePage] = useState(false);
    const [productSalesPage, setProductSalesPage] = useState(false);
    
    const [loadingEffect, setLoadingEffect] = useState(false);


  useEffect(() => {
    if(idRow){

      //Product Tab
      setProductPage(idRow.productPage)
      setCategoryPage(idRow.categoryPage)
      setStockControlPage(idRow.stockControlPage)

      //client Tab
      setCustomerPage(idRow.customerPage)
      setSupplierPage(idRow.supplierPage)
      setWareHousePage(idRow.wareHousePage)
      setDeliveryPage(idRow.deliveryPage)

      //Accounting Tab
      setCustomerAccountPage(idRow.customerAccountPage)
      setSupplierAccountPage(idRow.supplierAccountPage)
      setPurchaseInvoicePage(idRow.purchaseInvoicePage)
      setReturnInvoicePage(idRow.returnInvoicePage)
      setExpensesPage(idRow.expensesPage)
      setCashPage(idRow.cashPage)

      //Report Tab
      setCostChangesPage(idRow.costChangesPage)
      setStockActivityPage(idRow.stockActivityPage)
      setStockValuePage(idRow.stockValuePage)
      setProductSalesPage(idRow.productSalesPage)

    }
  }, [idRow]);
  
  const handleSubmit = async () => {

    setLoadingEffect(true);
    const id = idRow.id;

    const settings = {

      //Product Tab
      productPage,
      categoryPage,
      stockControlPage,

      //Client Tab
      customerPage,
      supplierPage,
      wareHousePage,
      deliveryPage,

      //Accouting Tab
      customerAccountPage,
      supplierAccountPage,
      purchaseInvoicePage,
      returnInvoicePage,
      expensesPage,
      cashPage,

      //Report Tab
      costChangesPage,
      stockActivityPage,
      stockValuePage,
      productSalesPage,

    }
      
    let res = await api.post(`/role/editPermissions`, JSON.stringify({id, settings}))
    if(res.data.status){
      toast.success(res.data.message);
      refreshTable();
      toggleModal();
    }
    else{
      toast.error(res.data.message);
    }

    setLoadingEffect(false);
 
  }





  return (
      <Modal show={showModal} backdrop="static" size='xl' onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>{idRow.name} Pages Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{userSelect: 'none', height: 'auto', display: 'flex', flexDirection: 'row' , marginBottom: '10%' }}>


     {/* Product Tab  */}
      <Form style={{width: '25%'}}>

      <h4 style={{textAlign: 'left'}}>Product Tab</h4>
      <div style={{borderTop: '1px solid #cccccc' , margin: '10px 0', marginTop: '4%', marginBottom: '15%'}}></div>

      <Form.Group as={Row} className="mb-2">
      <Form.Label column sm={8}>Product Page</Form.Label>
          <label class="switch">
          <input type="checkbox"
          onChange={() => setProductPage(!productPage)}
          checked = {productPage}
          ></input>
          <span class="slider"></span>
          </label>
        </Form.Group>


        <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={8}>Category Page</Form.Label>
          <label class="switch">
          <input type="checkbox"
          onChange={() => setCategoryPage(!categoryPage)}
          checked = {categoryPage}
          ></input>
          <span class="slider"></span>
          </label>
        </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={8} >Stock Control Page</Form.Label>
  
          <label class="switch">
          <input type="checkbox"
          onChange={() => setStockControlPage(!stockControlPage)}
          checked = {stockControlPage}
          ></input>
          <span class="slider"></span>
          </label>
   
        </Form.Group>
      </Form>


    {/* Client Tab  */}  
      <Form style={{width: '25%'}}>

      <h4 style={{textAlign: 'left'}}>Client Tab</h4>
      <div style={{borderTop: '1px solid #cccccc' , margin: '10px 0', marginTop: '4%', marginBottom: '15%'}}></div>

<Form.Group as={Row} className="mb-2">
<Form.Label column sm={8}>Customer Page</Form.Label>
    <label class="switch">
    <input type="checkbox"
    onChange={() => setCustomerPage(!customerPage)}
    checked = {customerPage}
    ></input>
    <span class="slider"></span>
    </label>
  </Form.Group>


  <Form.Group as={Row} className="mb-2">
  <Form.Label column sm={8}>Supplier Page</Form.Label>
    <label class="switch">
    <input type="checkbox"
    onChange={() => setSupplierPage(!supplierPage)}
    checked = {supplierPage}
    ></input>
    <span class="slider"></span>
    </label>
  </Form.Group>

<Form.Group as={Row}>
  <Form.Label column sm={8} >WareHouse Page</Form.Label>
    <label class="switch">
    <input type="checkbox"
    onChange={() => setWareHousePage(!wareHousePage)}
    checked = {wareHousePage}
    ></input>
    <span class="slider"></span>
    </label>

  </Form.Group>


  <Form.Group as={Row}>
  <Form.Label column sm={8} >Delivery Page</Form.Label>
    <label class="switch">
    <input type="checkbox"
    onChange={() => setDeliveryPage(!deliveryPage)}
    checked = {deliveryPage}
    ></input>
    <span class="slider"></span>
    </label>

  </Form.Group>

      </Form>




    {/* Accounting Tab  */}  
    <Form style={{width: '25%'}}>


    <h4 style={{textAlign: 'left'}}>Accounting Tab</h4>
      <div style={{borderTop: '1px solid #cccccc' , margin: '10px 0', marginTop: '4%', marginBottom: '15%'}}></div>

<Form.Group as={Row} className="mb-2">
<Form.Label column sm={8}>Customer Account Page</Form.Label>
    <label class="switch">
    <input type="checkbox"
    onChange={() => setCustomerAccountPage(!customerAccountPage)}
    checked = {customerAccountPage}
    ></input>
    <span class="slider"></span>
    </label>
  </Form.Group>


  <Form.Group as={Row} className="mb-2">
  <Form.Label column sm={8}>Supplier Account Page</Form.Label>
    <label class="switch">
    <input type="checkbox"
    onChange={() => setSupplierAccountPage(!supplierAccountPage)}
    checked = {supplierAccountPage}
    ></input>
    <span class="slider"></span>
    </label>
  </Form.Group>

<Form.Group as={Row}>
  <Form.Label column sm={8} >Purchase Invoice Page</Form.Label>
    <label class="switch">
    <input type="checkbox"
    onChange={() => setPurchaseInvoicePage(!purchaseInvoicePage)}
    checked = {purchaseInvoicePage}
    ></input>
    <span class="slider"></span>
    </label>
  </Form.Group>

  <Form.Group as={Row}>
  <Form.Label column sm={8} >Return Invoice Page</Form.Label>
    <label class="switch">
    <input type="checkbox"
    onChange={() => setReturnInvoicePage(!returnInvoicePage)}
    checked = {returnInvoicePage}
    ></input>
    <span class="slider"></span>
    </label>
  </Form.Group>


  <Form.Group as={Row}>
  <Form.Label column sm={8} >Expenses Page</Form.Label>
    <label class="switch">
    <input type="checkbox"
    onChange={() => setExpensesPage(!expensesPage)}
    checked = {expensesPage}
    ></input>
    <span class="slider"></span>
    </label>
  </Form.Group>

  <Form.Group as={Row}>
  <Form.Label column sm={8} >Cash Page</Form.Label>
    <label class="switch">
    <input type="checkbox"
    onChange={() => setCashPage(!cashPage)}
    checked = {cashPage}
    ></input>
    <span class="slider"></span>
    </label>
  </Form.Group>

      </Form>


    {/* Report Tab  */}  
    <Form style={{width: '25%'}}>


      <h4 style={{textAlign: 'left'}}>Report Tab</h4>
        <div style={{borderTop: '1px solid #cccccc' , margin: '10px 0', marginTop: '4%', marginBottom: '15%'}}></div>

      <Form.Group as={Row} className="mb-2">
      <Form.Label column sm={8}>Cost Change Page</Form.Label>
      <label class="switch">
      <input type="checkbox"
      onChange={() => setCostChangesPage(!costChangesPage)}
      checked = {costChangesPage}
      ></input>
      <span class="slider"></span>
      </label>
      </Form.Group>

      <Form.Group as={Row}>
      <Form.Label column sm={8} >Stock Activity Page</Form.Label>
      <label class="switch">
      <input type="checkbox"
      onChange={() => setStockActivityPage(!stockActivityPage)}
      checked = {stockActivityPage}
      ></input>
      <span class="slider"></span>
      </label>
      </Form.Group>

      <Form.Group as={Row}>
      <Form.Label column sm={8} >Stock Value Page</Form.Label>
      <label class="switch">
      <input type="checkbox"
      onChange={() => setStockValuePage(!stockValuePage)}
      checked = {stockValuePage}
      ></input>
      <span class="slider"></span>
      </label>
      </Form.Group>


      <Form.Group as={Row}>
      <Form.Label column sm={8} >Product Sales page</Form.Label>
      <label class="switch">
      <input type="checkbox"
      onChange={() => setProductSalesPage(!productSalesPage)}
      checked = {productSalesPage}
      ></input>
      <span class="slider"></span>
      </label>
      </Form.Group>

  </Form>

      </div>

      
            
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>

        <button type="button" className="btn btn-primary" onClick={handleSubmit}>
        Save Data
        </button>
        
        {loadingEffect && ( 
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        )}
      </div>
      </Modal.Body>
      </Modal>
      )
}


export default PagesPermission;